<template>


<div class="js-slider stage-video block-stage block-stage--regular stage-video--disable-nav" data-slider-stage="" data-slider-stage-disable="stage-video--disable-nav" data-slider-video="" data-slider-autoplay="true" data-slider-stage-playing="stage-video--playing">
    <div class="js-slider-items block-stage__items stage-video__items slick-initialized slick-slider">
        <div aria-live="polite" class="slick-list draggable">
            <div class="slick-track" style="opacity: 1; width: 1903px; transform: translate3d(0px, 0px, 0px);" role="listbox">
                <div class="block-stage__item slick-slide slick-current slick-active" style="width: 1903px;" tabindex="-1" role="option" aria-describedby="slick-slide00" data-slick-index="0" aria-hidden="false">
                    <picture class="block-stage__picture block-stage__picture--only">
                        <source media="(max-width: 360px)" srcset="/imagenes/Nosotros/slider/5.png">
                        <source media="(max-width: 479px)" srcset="/imagenes/Nosotros/slider/4.png">
                        <source media="(max-width: 767px)" srcset="/imagenes/Nosotros/slider/3.png">
                        <source media="(max-width: 1024px)" srcset="/imagenes/Nosotros/slider/2.png">
                        <source media="(min-width: 1025px)" srcset="/imagenes/Nosotros/slider/1.png">
                        <img src="" alt="DB Schenker Flags in the Sky" class="block-stage__img">
                    </picture>
                    <div class="block-stage__content wrapper wrapper--gutter"></div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="wrapper wrapper--gutter">
  <div class="text-block-intro-page">
      <h1 >Sobre Nosotros </h1>
      <div>
          <p>En Sunrise Cargo, estamos comprometidos a proporcionar soluciones innovadoras de cadena de
suministro que desafíen el status quo. Con más de 24 años de experiencia en logística, estamos orgullosos de
apoyar a la industria y el comercio en el intercambio global de mercancías.<br></p>
      </div>
  </div>
</div>

<section class="section has-margin has-tabs">
    <div class="wrapper block-teaser-full-overlay__wrapper">

<img src="/imagenes/Nosotros/IMAGEN_SOBRE_NOSOTROS.jpg" class="img-fluid" alt="Responsive image">
<a name="mision"></a>
        <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item " data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 21, 2021, 8:04:47 PM">
            <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                <b>Misión</b>
                <span class="dbs-accordion-item__arrow">
                    <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                        <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                        <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                    </svg>
                </span>
            </button>
            <div class="dbs-accordion-item__content">
                <div class="block-teaser--full-overlay block-teaser--row">
                    <picture class="block-teaser__media">
                        <source media="(max-width: 394px)" srcset="/imagenes/Nosotros/MISION.png">
                        <source media="(max-width: 507px)" srcset="/imagenes/Nosotros/MISION.png">
                        <source media="(max-width: 590px)" srcset="/imagenes/Nosotros/MISION.png">
                        <source media="(max-width: 767px)" srcset="/imagenes/Nosotros/MISION.png">
                        <source media="(max-width: 934px)" srcset="/imagenes/Nosotros/MISION.png">
                        <source media="(max-width: 1023px)" srcset="/imagenes/Nosotros/MISION.png">
                        <source media="(max-width: 1050px)" srcset="/imagenes/Nosotros/MISION.png">
                        <source media="(min-width: 1051px)" srcset="/imagenes/Nosotros/MISION.png">
                        <img src="/imagenes/Nosotros/MISION.png" alt="DB Schenker Employee Air Freight Product" class="">
                    </picture>
                    <div class="block-teaser__text theme-light block-teaser__text--with-links align-left" style="background-color:rgb(234 239 239 / 85%);">
                        <h2 class="h3 block-teaser__title">Misión</h2>
                        <p style="" data-truncate-lines="8">Somos el aliado estratégico de nuestros clientes, ofreciendo soluciones logísticas integrales que incluyan agenciamiento de carga,
                             agenciamiento aduanero, transporte terrestre, almacenamiento, distribución y acondicionamiento secundario de productos. 
                             Respaldados por un equipo altamente calificado y comprometido, utilizamos tecnologías innovadoras como inteligencia artificial (IA), automatización, 
                             análisis de datos y plataformas digitales avanzadas para optimizar procesos, mejorar la toma de decisiones en tiempo real y garantizar soluciones ágiles, 
                             personalizadas y confiables que respondan a las exigencias de un mercado dinámico y competitivo.
                        </p>
                        <p style="" data-truncate-lines="8">Con un enfoque en el crecimiento sostenible y estratégico, buscamos generar rentabilidad que promueva el desarrollo profesional, 
                            personal y económico de nuestros colaboradores, mientras fortalecemos el progreso de la empresa y afianzamos las relaciones con nuestras partes interesadas.
                        </p>
                        <p> 
                            Nos comprometemos a operar bajo los más altos estándares nacionales e internacionales que promuevan el cumplimiento de los requisitos estipulados por los entes 
                            de control y vigilancia. Esto con el fin de preservar la calidad, seguridad integral, la satisfacción de nuestros clientes y los demás grupos de interés.  
                        </p>
                        <a name="vision"></a>
                        <div class="grid grid--wide"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item dbs-accordion-item--first " data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 21, 2021, 8:04:46 PM">
            <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                <b>Visión</b>
                <span class="dbs-accordion-item__arrow">
                    <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                        <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                        <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                    </svg>
                </span>
            </button>
            <div class="dbs-accordion-item__content">
                <div class="block-teaser--full-overlay
                    block-teaser--row-reverse
                    ">
                    <picture class="block-teaser__media">
                        <source media="(max-width: 394px)" srcset="/imagenes/Nosotros/VISION.png">
                        <source media="(max-width: 507px)" srcset="/imagenes/Nosotros/VISION.png">
                        <source media="(max-width: 590px)" srcset="/imagenes/Nosotros/VISION.png">
                        <source media="(max-width: 767px)" srcset="/imagenes/Nosotros/VISION.png">
                        <source media="(max-width: 934px)" srcset="/imagenes/Nosotros/VISION.png">
                        <source media="(max-width: 1023px)" srcset="/imagenes/Nosotros/VISION.png">
                        <source media="(max-width: 1050px)" srcset="/imagenes/Nosotros/VISION.png">
                        <source media="(min-width: 1051px)" srcset="/imagenes/Nosotros/VISION.png">
                        <img src="/imagenes/Nosotros/VISION.png" alt="Land transport border crossing DB Schenker trucks" class="">
                    </picture>
                    <div class="block-teaser__text theme-dark block-teaser__text--with-links align-right" style="background-color:rgba(46,49,138,.85);">
                        <h2 class="h3 block-teaser__title">Visión</h2>
                        <p data-truncate-lines="8" style="">
                            Nuestra visión es consolidarnos como un proveedor líder en soluciones logísticas integrales en Colombia, siendo un referente de 
                            confianza e innovación en el mercado. Aspiramos a transformar el sector logístico mediante el uso de tecnologías avanzadas y un 
                            enfoque sostenible, adaptándonos constantemente a las necesidades de un entorno dinámico. Queremos ser reconocidos por nuestra 
                            excelencia operativa, el impacto positivo en el entorno y el compromiso con la calidad y la seguridad en cada etapa de la cadena logística.
                        </p>
                        <a name="politica"></a>
                        <div class="grid grid--wide"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item " data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 21, 2021, 8:04:47 PM">
            <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                <b>Política</b>
                <span class="dbs-accordion-item__arrow">
                    <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                        <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                        <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                    </svg>
                </span>
            </button>
            <div class="dbs-accordion-item__content">
                <div class="block-teaser--full-overlay
                    block-teaser--row
                    ">
                    <picture class="block-teaser__media" style="height: 100%;min-width: 66.66%;">
                        <source media="(max-width: 394px)" srcset="/imagenes/Nosotros/POLITICA.png">
                        <source media="(max-width: 507px)" srcset="/imagenes/Nosotros/POLITICA.png">
                        <source media="(max-width: 590px)" srcset="/imagenes/Nosotros/POLITICA.png">
                        <source media="(max-width: 767px)" srcset="/imagenes/Nosotros/POLITICA.png">
                        <source media="(max-width: 934px)" srcset="/imagenes/Nosotros/POLITICA.png">
                        <source media="(max-width: 1023px)" srcset="/imagenes/Nosotros/POLITICA.png">
                        <source media="(max-width: 1050px)" srcset="/imagenes/Nosotros/POLITICA.png">
                        <source media="(min-width: 1051px)" srcset="/imagenes/Nosotros/POLITICA.png">
                        <img src="/imagenes/Nosotros/POLITICA.png" alt="DB Schenker Employee Air Freight Product" class="">
                    </picture>
                    <div class="block-teaser__text theme-light block-teaser__text--with-links align-left" style="background-color:rgb(234 239 239 / 85%);">
                        <h2 class="h3 block-teaser__title">Política</h2>
                        <p style="" data-truncate-lines="8">
                            1- La maximización de la satisfacción del cliente, la seguridad integral de nuestros procesos organizacionales y el cumplimiento de las necesidades y expectativas de las partes interesadas.
                        </p>
                        <p style="" data-truncate-lines="8">
                            2- La calidad y seguridad de nuestros procesos organizacionales, a través de las soluciones logísticas y controles aplicables en las cadenas de suministro.
                        </p>
                        <p style="" data-truncate-lines="8">
                            3- La prevención y mitigación de los riesgos asociados a las actividades fraudulentas, ilícitas, ilegitimas, corrupción, narcotráfico, LAFT, contrabando, PDAM y soborno transnacional en los actos, operaciones, negocios o contratos que lleve a cabo la empresa y los demás riesgos aplicables a la organización.
                        </p>
                        <p style="" data-truncate-lines="8">
                            4- La protección del ambiente y la prevención de la contaminación mediante la adecuada gestión recursos para gestionar nuestros aspectos e impactos ambientales.
                        </p>
                        <p style="" data-truncate-lines="8">
                            5- La identificación de los peligros, evaluación y valoración de los riesgos para determinar los respectivos controles y medidas de intervención a través de la implementación del Sistema de Seguridad y salud en el trabajo.
                        </p>
                        <p style="" data-truncate-lines="8">
                            6- La gestión de la seguridad vial asociada a la organización PESV
                        </p>
                        <p style="" data-truncate-lines="8">7- La continuidad en la prestación de nuestros servicios, cuando se presente un evento de interrupción.</p>
                        <p style="" data-truncate-lines="8">8- El cumplimiento de los requisitos legales, reglamentarios y otros requisitos aplicables a la organización.</p>
                        <p style="" data-truncate-lines="8">9- La mejora continua de nuestra organización.</p>
                        <a name="codigo_etica"></a>
                        <div class="grid grid--wide"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item dbs-accordion-item--first " data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 21, 2021, 8:04:46 PM">
            <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                <b>Código de ética</b>
                <span class="dbs-accordion-item__arrow">
                    <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                        <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                        <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                    </svg>
                </span>
            </button>
            <div class="dbs-accordion-item__content">
                <div class="block-teaser--full-overlay
                    block-teaser--row-reverse
                    ">
                    <picture class="block-teaser__media">
                        <source media="(max-width: 394px)" srcset="/imagenes/Nosotros/CODIGO-DE-ETICA.png">
                        <source media="(max-width: 507px)" srcset="/imagenes/Nosotros/CODIGO-DE-ETICA.png">
                        <source media="(max-width: 590px)" srcset="/imagenes/Nosotros/CODIGO-DE-ETICA.png">
                        <source media="(max-width: 767px)" srcset="/imagenes/Nosotros/CODIGO-DE-ETICA.png">
                        <source media="(max-width: 934px)" srcset="/imagenes/Nosotros/CODIGO-DE-ETICA.png">
                        <source media="(max-width: 1023px)" srcset="/imagenes/Nosotros/CODIGO-DE-ETICA.png">
                        <source media="(max-width: 1050px)" srcset="/imagenes/Nosotros/CODIGO-DE-ETICA.png">
                        <source media="(min-width: 1051px)" srcset="/imagenes/Nosotros/CODIGO-DE-ETICA.png">
                        <img src="/imagenes/Nosotros/CODIGO-DE-ETICA.png" alt="Land transport border crossing DB Schenker trucks" class="">
                    </picture>
                    <div class="block-teaser__text theme-dark block-teaser__text--with-links align-right" style="background-color:rgba(46,49,138,.85);">
                        <h2 class="h3 block-teaser__title">Código de ética</h2>
                        <p data-truncate-lines="8" style="">SUNRISE CARGO está comprometida con la sostenibilidad de nuestra
                            empresa a fin de asegurar el éxito a largo plazo. Nuestros socios, empleados,
                            clientes y proveedores desempeñan un papel importante como
                            facilitadores de nuestro crecimiento sostenible, razón por la cual es
                            fundamental que cada una de las partes sigan los siguientes lineamientos de
                            nuestro código de ética. El incumplimiento del código de ética de la
                            empresa es causal de despido.
                        </p>
                        <a href="/imagenes/Nosotros/R-SIG-01_Codigo_de_Etica.pdf" target="_blank" class="btn btn--border btn--default hide show-portable " tabindex="" style="background: white;color: #2E318A;"><span>Ver más</span></a>
                        <div class="btn btn--border btn--default hide show-portable " style="border: none; text-align: right; padding: 0;">
                            <a href="mailto:etica@sunrisecargo.com"  class="btn btn--border btn--default" style="border: none; color: white; padding: 0;" ><p style="text-align: right;"><b>Email : etica@sunrisecargo.com</b><img src="/imagenes/email1.png" alt="..." style="width: 72px; margin-right: 20px;" ></p></a>
                        </div>
                        <a name="RecomendacionesSeguridad"></a>
                        <div class="grid grid--wide"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item " data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 21, 2021, 8:04:47 PM">
            <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                <b>Recomendaciones de seguridad</b>
                <span class="dbs-accordion-item__arrow">
                    <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                        <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                        <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                    </svg>
                </span>
            </button>
            <div class="dbs-accordion-item__content">
                <div class="block-teaser--full-overlay
                    block-teaser--row
                    ">
                    <picture class="block-teaser__media" style="height: 100%;min-width: 66.66%;">
                        <source media="(max-width: 394px)" srcset="/imagenes/Nosotros/Recomendaciones_Seguridad.png">
                        <source media="(max-width: 507px)" srcset="/imagenes/Nosotros/Recomendaciones_Seguridad.png">
                        <source media="(max-width: 590px)" srcset="/imagenes/Nosotros/Recomendaciones_Seguridad.png">
                        <source media="(max-width: 767px)" srcset="/imagenes/Nosotros/Recomendaciones_Seguridad.png">
                        <source media="(max-width: 934px)" srcset="/imagenes/Nosotros/Recomendaciones_Seguridad.png">
                        <source media="(max-width: 1023px)" srcset="/imagenes/Nosotros/Recomendaciones_Seguridad.png">
                        <source media="(max-width: 1050px)" srcset="/imagenes/Nosotros/Recomendaciones_Seguridad.png">
                        <source media="(min-width: 1051px)" srcset="/imagenes/Nosotros/Recomendaciones_Seguridad.png">
                        <img src="/imagenes/Nosotros/Recomendaciones_Seguridad.png" alt="DB Schenker Employee Air Freight Product" class="">
                    </picture>
                    <div class="block-teaser__text theme-light block-teaser__text--with-links align-left" style="background-color:rgb(234 239 239 / 85%);">
                        <h2 class="h3 block-teaser__title">Recomendaciones de seguridad</h2>
                        <p style="" data-truncate-lines="8">Estimado Cliente, Sunrise Cargo S.A.S. se encuentra debidamente certificado bajo estándares  ISO 28000 e ISO 9001, que garantizan la seguridad de las mercancías. Debido a que existen diferentes actores y procesos de la cadena logística en la cual no estamos involucrados, lo invitamos a tomar las siguientes medidas para que su proceso esté controlado en todas las etapas.
                        </p>
                        <p style="" data-truncate-lines="8">1.	Notificar formalmente a Sunrise Cargo y con la debida antelación instrucciones completas para el manejo, manipulación de mercancías y elaboración de documentos. Indicar claramente nuestro alcance en el proceso.
                        </p>
                        <p style="" data-truncate-lines="8">2.	Verificar documentación remitida por Sunrise Cargo y confirmar si está de acuerdo con el contenido de la misma, o solicitar de manera oportuna las correcciones a las que haya lugar.
                        </p>
                        <p style="" data-truncate-lines="8">3.	Transmitir oportunamente documentación, de manera física con remisión formal o documentación virtual a través de archivos PDF; de tal manera que no sean susceptibles a algún tipo de alteración.
                        </p>
                        <p style="" data-truncate-lines="8">4.	Actualizar documentación legal de su empresa, en el momento que se le solicite.
                        </p>
                        <p style="" data-truncate-lines="8">5.	Tomar fotografías durante el proceso de cargue y/o descargue de mercancías.
                        </p>
                        <p style="" data-truncate-lines="8">6.	Realizar inspección a los contenedores antes del cargue y/o descargue de mercancías, Notificar oportunamente a Sunrise Cargo fecha y hora de cargue de mercancías de tal manera que logremos coordinar presencia de nuestros funcionarios en el lugar. O manifestar razones por las cuales no es permitida la asistencia y verificación del proceso.</p>
                        <p style="" data-truncate-lines="8">7.	Dejar constancia escrita sobre las anomalías que  pueda detectar y notificar de inmediato a Sunrise Cargo y/o compañía de seguros cuando la situación lo amerite.
                        </p>
                        <p style="" data-truncate-lines="8">8.	Cuando de exportaciones se trate, utilizar sellos de seguridad que cumplan con lo estipulado en la norma ISO 17712.</p>
                        <p style="" data-truncate-lines="8">9.	Cerificar que el embarque ha sido preparado en lugares con óptimas condiciones de seguridad y  protegido de toda intervención ilícita durante su preparación, embalaje, almacenamiento y transporte hacia las instalaciones de la aerolínea y/o puerto  y cumple con todos los requisitos exigidos por la ley.</p>
                        <a href="/pdf_sunrisecargo/F-C-01_Proceso_de_Reclamos_V30.pdf" target="_blank"><h4 class="h4 py-2" style="color: gray;"> <img src="/imagenes/pdf3.gif" class="rounded" alt="..." style="width: 72px; margin-right: 20px;" > Proceso de reclamos</h4></a>
                        <a href="/contactenos.html" ><p style="text-align: right;"><b>En caso de requerir información adicional, contáctenos.</b><img src="/imagenes/email1.png" alt="..." style="width: 72px; margin-right: 20px;" ></p></a>
                        <div class="grid grid--wide"></div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</section>


</template>

<script>
export default {


}
</script>

<style>

</style>